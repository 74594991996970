import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, effect, inject } from '@angular/core';
import { UIAlertDialogService } from '@bannerflow/ui';
import jsZip from 'jszip';
import { Subject } from 'rxjs';
import { Brand } from 'src/models/brand.model';
import {
    FontBufferData,
    FontFamily,
    FontRenameRequest,
    FontStyle,
    FontStyleResponse,
    IFontFamilyJSON,
    NewFontFamilyDto,
    NewFontStyleDto
} from '../models/fontFamily.model';
import { AuthService } from './auth.service';
import { EnvironmentService } from './environment.service';
import { HttpProxyService } from './http-proxy.service';

@Injectable({
    providedIn: 'root'
})
export class FontService {
    private environmentService = inject(EnvironmentService);
    private httpProxy = inject(HttpProxyService);
    private alertDialogService = inject(UIAlertDialogService);
    private authService = inject(AuthService);

    private apiUrl = this.environmentService.getOrigin('fontManagerApi');

    isLoading: boolean;
    private _fontFamilies: FontFamily[] = [];

    get fontFamilies(): FontFamily[] {
        return this._fontFamilies;
    }
    set fontFamilies(fontFamilies: FontFamily[]) {
        this._fontFamilies = fontFamilies;
        this.fontsUpdate.next();
    }

    isAllowedToModifyFont = (fontFamily: FontFamily): boolean =>
        (fontFamily && !fontFamily.isAccountFont) ||
        (fontFamily && fontFamily.isAccountFont && this.authService.isAdmin());

    deletedFontFamily: Subject<void> = new Subject<void>();
    brands: Brand[] = [];
    brandId: string;

    hasError: boolean;
    fontsUpdate: Subject<void> = new Subject<void>();
    afterFilesUploaded: Subject<FontFamily | undefined> = new Subject<FontFamily | undefined>();

    constructor() {
        // Trigger getAllBrands when the isAdmin signal changes
        // (Auth0 might take some time to return the user)
        effect(async () => {
            const isAdmin = this.authService.isAdmin();
            if (isAdmin) {
                this.brands = await this.getAllBrands();
            }
        });
    }

    private async setupHeaders(): Promise<HttpHeaders> {
        this.hasError = false;

        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        return headers;
    }

    getInstalledOnThisBrandFonts(): FontFamily[] {
        const brandFamilies: FontFamily[] = this.fontFamilies
            .filter((family: FontFamily) => family.brandIds.includes(this.brandId))
            .sort((a, b) => a.name.localeCompare(b.name));

        return brandFamilies;
    }

    getNotOnThisBrandFonts(): FontFamily[] {
        const nonLocalAccountFonts: FontFamily[] = this.fontFamilies
            .filter(
                (family: FontFamily) => family.isAccountFont && !family.brandIds.includes(this.brandId)
            )
            .sort((a, b) => a.name.localeCompare(b.name));

        return nonLocalAccountFonts;
    }

    getFontStyleName(fontFamily: FontFamily, fontStyle: FontStyle): string {
        let sanitizedFontFamilyName = fontFamily.name.replace(/[^a-zA-Z0-9]/g, '');
        sanitizedFontFamilyName = sanitizedFontFamilyName.replace(/ /g, '');

        return `fontfamily${sanitizedFontFamilyName}${fontStyle.id}`;
    }

    getFontFile(fileUrl: string): Promise<Blob> {
        return this.httpProxy.get<Blob>(fileUrl, {
            responseType: 'blob'
        });
    }

    async getAllFontFamilies(includeDeleted?: boolean): Promise<FontFamily[]> {
        this.isLoading = true;

        const includeDeletedParam = includeDeleted ? '?includeDeleted=true' : '';

        if (!this.authService.isAuthenticated()) {
            return [];
        }

        try {
            const headers: HttpHeaders = await this.setupHeaders();
            const response: IFontFamilyJSON[] = await this.httpProxy.get<IFontFamilyJSON[]>(
                `${this.apiUrl}/font/brand/${this.brandId + includeDeletedParam}`,
                { headers }
            );

            this.fontFamilies = response.reduce<FontFamily[]>((acc, data) => {
                const font: FontFamily | null = FontFamily.deserialize(data);
                if (font) {
                    this.isLoading = false;

                    return [...acc, font];
                }

                return acc;
            }, []);

            this.isLoading = false;

            return this.fontFamilies;
        } catch (error) {
            this.isLoading = false;
            this.displayError(
                error,
                'Something went wrong when trying to fetching all the fonts. Please try again.'
            );

            return [];
        }
    }

    async getFontFamily(fontFamily: FontFamily): Promise<FontFamily | null> {
        this.isLoading = true;

        if (!this.authService.isAuthenticated()) {
            this.isLoading = false;

            return null;
        }

        try {
            const headers: HttpHeaders = await this.setupHeaders();

            const response: IFontFamilyJSON | null = await this.httpProxy.get<IFontFamilyJSON | null>(
                `${this.apiUrl}/font/${fontFamily.id}`,
                { headers }
            );
            const fontFamilyObject: FontFamily | null = FontFamily.deserialize(response);
            this.isLoading = false;

            return fontFamilyObject;
        } catch (error) {
            this.isLoading = false;
            this.displayError(
                error,
                'Something went wrong when trying to fetch a font family. Please try again.'
            );

            return null;
        }
    }

    async getAllBrands(): Promise<Brand[]> {
        this.isLoading = true;
        const isAuthed = this.authService.isAuthenticated();
        const isAdmin = this.authService.isAdmin();

        if (!isAuthed || !isAdmin) {
            this.isLoading = false;

            return [];
        }
        try {
            const headers: HttpHeaders = await this.setupHeaders();
            const response: Brand[] | null = await this.httpProxy.get<Brand[]>(
                `${this.apiUrl}/Brand/${this.brandId}`,
                { headers }
            );
            this.isLoading = false;

            return response;
        } catch (error) {
            this.isLoading = false;
            console.error(error);
            this.displayError(
                error,
                'Something went wrong when trying to fetch a font family. Please try again.'
            );

            return [];
        }
    }

    async createFontFamily(newFontFamily: NewFontFamilyDto): Promise<FontFamily | void> {
        this.isLoading = true;
        try {
            const headers: HttpHeaders = await this.setupHeaders();
            const response: FontFamily = await this.httpProxy.post<FontFamily, NewFontFamilyDto>(
                `${this.apiUrl}/font`,
                newFontFamily,
                {
                    headers
                }
            );
            this.isLoading = false;

            this.fontFamilies.push(response);

            return response;
        } catch (error) {
            this.isLoading = false;

            if (error instanceof HttpErrorResponse && error.status === 422) {
                this.displayError(
                    error,
                    'Unable to subset font. The font file is possibly corrupted. Try with a different font file.'
                );
            } else {
                this.displayError(
                    error,
                    'Something went wrong when trying to create the font family. Please try again.'
                );
            }
        }
    }

    async renameFontFamily(fontFamily: FontFamily, newName: string): Promise<void> {
        this.isLoading = true;

        try {
            const body: FontRenameRequest = { name: newName };
            const headers: HttpHeaders = await this.setupHeaders();
            await this.httpProxy.patch(`${this.apiUrl}/font/${fontFamily.id}`, body, { headers });

            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
            this.displayError(
                error,
                'Something went wrong when trying to rename the font family. Please try again.'
            );
        }
    }

    async moveFontStyles(
        fontFamily: FontFamily,
        mergeTo: FontFamily,
        stylesToMove: FontStyle[]
    ): Promise<FontFamily | null> {
        this.isLoading = true;

        try {
            const headers: HttpHeaders = await this.setupHeaders();

            const fontStyleIds: string[] = stylesToMove.map((style: FontStyle) => style.id);

            const body: { fontStyleIds: string[] } = {
                fontStyleIds
            };
            this.isLoading = false;

            const response: IFontFamilyJSON = await this.httpProxy.put(
                `${this.apiUrl}/font/${fontFamily.id}/styles/move/${mergeTo.id}`,
                body,
                { headers }
            );
            const fontFamilyObject: FontFamily | null = FontFamily.deserialize(response);
            if (fontFamilyObject) {
                if (fontFamily.fontStyles.length - stylesToMove.length === 0) {
                    await this.deleteFontFamily(fontFamily, true);
                }
                this.isLoading = false;

                return fontFamilyObject;
            }
        } catch (error) {
            this.isLoading = false;
            this.displayError(error, 'Something went wrong when moving font styles. Please try again.');
        }

        return null;
    }

    async mergeFontFamilies(
        sourceFontFamilyId: string,
        targetFontFamilyId: string
    ): Promise<FontFamily | null> {
        this.isLoading = true;
        try {
            const headers: HttpHeaders = await this.setupHeaders();
            type MergeRequest = Readonly<{
                sourceFontFamilyId: string;
                targetFontFamilyId: string;
            }>;
            const body: MergeRequest = {
                sourceFontFamilyId,
                targetFontFamilyId
            };

            const response: IFontFamilyJSON = await this.httpProxy.put<IFontFamilyJSON, MergeRequest>(
                `${this.apiUrl}/font/merge`,
                body,
                {
                    headers
                }
            );
            const font: FontFamily | null = FontFamily.deserialize(response);

            if (font) {
                this.fontFamilies = [
                    ...this.fontFamilies.filter(
                        x => x.id !== sourceFontFamilyId && x.id !== targetFontFamilyId
                    ),
                    font
                ];
            }
            this.isLoading = false;

            return font;
        } catch (error) {
            this.displayError(
                error,
                'Something went wrong when merging fontfamilies. Please try again.'
            );
        }
        this.isLoading = false;

        return null;
    }

    async addFontStyles(
        fontFamilyId: string,
        newFontStyles: NewFontStyleDto[]
    ): Promise<FontStyleResponse | void> {
        this.isLoading = true;

        try {
            const headers: HttpHeaders = await this.setupHeaders();
            const response: FontStyleResponse[] = await this.httpProxy.put<
                FontStyleResponse[],
                NewFontStyleDto[]
            >(`${this.apiUrl}/font/${fontFamilyId}/style`, newFontStyles, { headers });
            const fontStyles: FontStyle[] = [];

            response.forEach(fontStyleJSON => {
                const newFontStyle: FontStyle | null = FontStyle.deserialize(fontStyleJSON);
                if (newFontStyle) {
                    return fontStyles.push(newFontStyle);
                }
            });

            for (const fontFamily of this.fontFamilies) {
                if (fontFamily.id === fontFamilyId) {
                    fontFamily.fontStyles = fontStyles;
                }
            }

            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
            this.displayError(
                error,
                'Something went wrong when trying to add font style. Please try again.'
            );
        }
    }

    async renameFontStyle(
        fontFamily: FontFamily,
        fontStyle: FontStyle,
        newName: string
    ): Promise<void> {
        this.isLoading = true;
        try {
            const body: FontRenameRequest = { name: newName };
            const headers: HttpHeaders = await this.setupHeaders();
            await this.httpProxy.patch(
                `${this.apiUrl}/font/${fontFamily.id}/style/${fontStyle.id}`,
                body,
                { headers }
            );
        } catch (error) {
            this.displayError(
                error,
                'Something went wrong when trying to rename the font style. Please try again.'
            );
        }

        this.isLoading = false;
    }

    async deleteFontStyles(fontFamily: FontFamily, fontStyles: FontStyle[]): Promise<void> {
        this.isLoading = true;
        const list = fontStyles.reduce<string>(
            (acc, style) => (acc += `fontStyleIds=${style.id}&`),
            ''
        );
        try {
            const headers: HttpHeaders = await this.setupHeaders();
            await this.httpProxy.delete(`${this.apiUrl}/font/${fontFamily.id}/style?${list}`, {
                headers
            });
        } catch (error) {
            this.displayError(
                error,
                'Something went wrong when trying to delete the font style. Please try again.'
            );
        }

        if (fontFamily.fontStyles.length - fontStyles.length === 0) {
            await this.deleteFontFamily(fontFamily, true);
        } else {
            this.isLoading = false;
        }
    }

    async deleteFontFamily(fontFamilyToDelete: FontFamily, emit = false): Promise<void> {
        this.isLoading = true;

        try {
            const headers: HttpHeaders = await this.setupHeaders();
            await this.httpProxy.delete(`${this.apiUrl}/font/${fontFamilyToDelete.id}`, { headers });

            this.fontFamilies = this.fontFamilies.filter(
                (fontFamily: FontFamily) => fontFamily.id !== fontFamilyToDelete.id
            );

            if (emit) {
                this.deletedFontFamily.next();
            }
        } catch (error) {
            this.displayError(
                error,
                'Something went wrong when trying to delete the font family. Please try again.'
            );
        }

        this.isLoading = false;
    }

    async updateAccountFont(
        fontFamily: FontFamily,
        brandIds: string[]
    ): Promise<IFontFamilyJSON | null> {
        this.isLoading = true;

        try {
            const headers: HttpHeaders = await this.setupHeaders();
            this.isLoading = false;

            return this.httpProxy.put(
                `${this.apiUrl}/font/${fontFamily.id}/convert`,
                { brandIds },
                { headers }
            );
        } catch (error) {
            this.isLoading = false;
            this.displayError(
                error,
                'Something went wrong when trying to convert the font family to AccountFont. Please try again.'
            );

            return null;
        }
    }

    private async getFontData(url: string): Promise<FontBufferData> {
        const type: string | undefined = url.split('.').pop();
        if (!type) {
            throw new Error(`Unable to determine type of ${url}`);
        }
        const buffer: ArrayBuffer = await this.httpProxy.get<ArrayBuffer>(url, {
            responseType: 'arraybuffer'
        });

        return { buffer, type };
    }

    downloadFont = (fontStyle: FontStyle): void => {
        window.location.href = fontStyle.fontUrl;
    };

    async downloadFonts(fontStyles: FontStyle[]): Promise<void> {
        const zip: Readonly<jsZip> = new jsZip();
        let zippedFiles = 0;
        for (const style of fontStyles) {
            try {
                const { buffer, type } = await this.getFontData(style.fontUrl);

                const fileName = `${style.name}.${type}`;

                zip.file(fileName, buffer, {
                    binary: true
                });
                zippedFiles += 1;
            } catch (error) {
                console.error(error);
            }
        }
        if (zippedFiles) {
            const zipContent: Blob = await zip.generateAsync({ type: 'blob' });
            const url = URL.createObjectURL(zipContent);

            const fileLink: HTMLAnchorElement = document.createElement('a');
            fileLink.href = url;
            fileLink.download = 'fonts';
            fileLink.click();
        } else {
            this.displayError(
                undefined,
                'Something went wrong when trying to download the fonts. Please try again.'
            );
        }
    }

    displayError(error?: HttpErrorResponse | unknown, errorText?: string): void {
        this.hasError = true;

        if (
            error instanceof HttpErrorResponse &&
            error.status &&
            (error.status === 401 || error.status === 403)
        ) {
            if (error.status === 403) {
                this.alertDialogService.show({
                    headerText: 'You are not authorized',
                    text: 'You are not authorized to access this page',
                    confirmText: 'Ok'
                });
            } else {
                this.isLoading = true;
                this.authService.login();
            }
        } else {
            this.alertDialogService.show({
                headerText: 'An error has occured',
                text: errorText
                    ? errorText
                    : 'Something went wrong when communicating with the server. Please try again.',
                confirmText: 'Close'
            });
        }
    }
}
